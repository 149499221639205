import React from 'react';

interface PreviewHeaderProps {
  animalType: string;
}

const PreviewHeader: React.FC<PreviewHeaderProps> = ({ animalType }) => {
  return (
    <div className="bg-red-600 text-white text-center py-8">
      <h1 className="text-8xl font-extrabold">
        <span className="text-9xl text-yellow-400">{animalType}</span>を探しています!
      </h1>
    </div>
  );
};

export default PreviewHeader;

