import React from 'react';

interface MissingDateInputProps {
  missingDate: string;
  setMissingDate: React.Dispatch<React.SetStateAction<string>>;
}

const MissingDateInput: React.FC<MissingDateInputProps> = ({ missingDate, setMissingDate }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">行方不明の日付:</label>
      <input
        type="date"
        value={missingDate}
        onChange={(e) => setMissingDate(e.target.value)}
        className="border p-2 w-full"
      />
    </div>
  );
};

export default MissingDateInput;
