import React from 'react';

interface NotesInputProps {
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
}

const NotesInput: React.FC<NotesInputProps> = ({ notes, setNotes }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">メモ:</label>
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        className="border p-2 w-full"
      />
    </div>
  );
};

export default NotesInput;
