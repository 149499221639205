import React, { useRef, useEffect, useState } from 'react';
import { FaEnvelope, FaPhone, FaTwitter } from 'react-icons/fa';  // react-iconsをインポート

interface PreviewFooterProps {
  email: string;
  twitterHandle: string;
  phoneNumber: string;  // 電話番号を追加
}

const PreviewFooter: React.FC<PreviewFooterProps> = ({ email, twitterHandle, phoneNumber }) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [fontSize, setFontSize] = useState(24);  // 初期フォントサイズ

  useEffect(() => {
    const adjustFontSize = () => {
      const element = textRef.current;
      if (element) {
        const { clientWidth, scrollWidth } = element;
        
        // テキストが要素の幅を超えたらフォントサイズを調整
        if (scrollWidth > clientWidth) {
          setFontSize((prevSize) => prevSize - 1);
        }
      }
    };

    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, [fontSize]);

  const hasContactInfo = email || phoneNumber || twitterHandle;  // いずれかの連絡先情報が存在するか確認

  return (
    <div className="bg-red-600 text-white py-10 mt-auto relative">
      <p 
        ref={textRef} 
        className="text-center font-bold text-yellow-400" 
        style={{ fontSize: `${fontSize}px`, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        保護していただいている方、似ている猫を見つけた方、間違いでも結構ですのでご連絡ください。
      </p> {/* フォントサイズを自動調整し、1行に収める */}
      
      {hasContactInfo && (  // 連絡先情報がある場合のみ表示
        <div className="flex justify-center items-center mt-4 space-x-6"> {/* 横並びにするためにflexを使用 */}
          {email && (
            <p className="flex items-center">
              <FaEnvelope className="mr-2" /> {email}
            </p>
          )}
          {phoneNumber && (
            <p className="flex items-center">
              <FaPhone className="mr-2" /> {phoneNumber}
            </p>
          )}
          {twitterHandle && (
            <p className="flex items-center">
              <FaTwitter className="mr-2" /> {twitterHandle}
            </p>
          )}
        </div>
      )}
      
      {/* 右下: 自社名 */}
      <div className="absolute bottom-2 right-4">
        <p>uskado</p>
      </div>
    </div>
  );
};

export default PreviewFooter;
