import React from 'react';
import { FaDog, FaCat, FaDove, FaMars, FaVenus, FaCalendarAlt, FaPaw } from 'react-icons/fa';

interface PreviewContentsProps {
  animalType: string; // 動物の種類
  imageUrl1: string;
  imageUrl2: string;
  missingDate: string;  // 日付
  missingLocation: string;  // 場所
  name: string;
  gender: string;
  age: string;
  collar: string;
  features: string;
  notes: string;
}

const PreviewContents: React.FC<PreviewContentsProps> = ({
  animalType, // animalTypeを受け取る
  imageUrl1,
  imageUrl2,
  missingDate,
  missingLocation,
  name,
  gender,
  age,
  collar,
  features,
  notes
}) => {
  // animalTypeに応じてアイコンを切り替える関数
  const getAnimalIcon = () => {
    switch (animalType) {
      case '猫':
        return <FaCat className="mr-2 text-red-600" style={{ fontSize: '24px' }} />;
      case '犬':
        return <FaDog className="mr-2 text-red-600" style={{ fontSize: '24px' }} />;
      case '鳥':
        return <FaDove className="mr-2 text-red-600" style={{ fontSize: '24px' }} />;
      default:
        return <FaPaw className="mr-2 text-red-600" style={{ fontSize: '24px' }} />;  // デフォルトのアイコン
    }
  };

  return (
    <div className="text-center p-4 h-full">
      <p className="text-4xl font-bold mb-4">
        {formatDate(missingDate)}「{missingLocation}」で行方不明
      </p>
      <div className="flex mt-4 h-[calc(100%-4rem)]">
        <div className="w-1/2 pr-2 h-full flex">
          {imageUrl1 ? (
            <img src={imageUrl1} alt="Pet 1" className="w-full h-full object-cover rounded-lg shadow-md" />
          ) : (
            <div className="w-full h-full bg-gray-300 flex items-center justify-center rounded-lg shadow-md">
              <span className="text-gray-500">画像をアップロードしてください</span>
            </div>
          )}
        </div>
        <div className="w-1/2 flex flex-col h-full">
          <div className="flex-grow mb-2 h-full flex">
            {imageUrl2 ? (
              <img src={imageUrl2} alt="Pet 2" className="w-full h-full object-cover rounded-lg shadow-md" />
            ) : (
              <div className="w-full h-full bg-gray-300 flex items-center justify-center rounded-lg shadow-md">
                <span className="text-gray-500">画像をアップロードしてください</span>
              </div>
            )}
          </div>
          <div className="flex-grow bg-white flex flex-col justify-start p-4 text-left h-full rounded-lg shadow-md">
            <ul className="list-none grid grid-cols-2 gap-x-4 gap-y-2">
              <li className="col-span-2 flex items-center justify-center text-lg"> {/* 名前を中央寄せ */}
                {getAnimalIcon()} 
                <strong>名前:</strong> {name}
              </li>
              <li className="flex items-center text-lg">
                {gender === 'オス' ? <FaMars className="mr-2 text-blue-600" style={{ fontSize: '24px' }} /> : <FaVenus className="mr-2 text-pink-600" style={{ fontSize: '24px' }} />} 
                <strong>性別:</strong> {gender}
              </li>
              <li className="flex items-center text-lg">
                <FaCalendarAlt className="mr-2 text-green-600" style={{ fontSize: '24px' }} />
                <strong>年齢:</strong> {age ? `${age}歳` : age}
              </li>
              <li className="flex items-center text-lg">
                <FaPaw className="mr-2 text-yellow-600" style={{ fontSize: '24px' }} />
                <strong>首輪:</strong> {collar}
              </li>
              <li className="flex items-center text-lg">
                <FaPaw className="mr-2 text-purple-600" style={{ fontSize: '24px' }} />
                <strong>マイクロチップ:</strong> {features}
              </li>
              <li className="col-span-2 flex items-start text-lg"> {/* メモを上寄せにして表示 */}
                <FaPaw className="mr-2 text-gray-600" style={{ fontSize: '24px', flexShrink: 0 }} /> {/* アイコンのサイズを固定、flexShrinkを無効化 */}
                <div>
                  <strong>メモ:</strong> 
                  <p className="mt-1 break-words">{notes}</p> {/* メモの内容は改行し、アイコンが圧迫されないように */}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

// 日付を「〇年〇月〇日」形式にフォーマットする関数
const formatDate = (dateString: string): string => {
  const date = dateString ? new Date(dateString) : new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月は0始まりなので+1する
  const day = date.getDate();
  return `${year}年${month}月${day}日`;
};

export default PreviewContents;
