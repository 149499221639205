import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import ReactModal from 'react-modal';
import getCroppedImg from './getCroppedImg';

interface ImageCropModalProps {
  imageSrc: string;
  onSave: (croppedImage: string) => void;
  onClose: () => void;
  aspect: number;  // ここでアスペクト比を受け取る
}

const ImageCropModal: React.FC<ImageCropModalProps> = ({ imageSrc, onSave, onClose, aspect }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = useCallback(async () => {
    if (!croppedAreaPixels) return;
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onSave(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageSrc, onSave]);

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-40"
      appElement={document.getElementById('root')!}
    >
      <div className="relative bg-white p-4 rounded-lg shadow-lg w-full max-w-3xl">
        <div className="relative w-full h-64 sm:h-96">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}  // ここにアスペクト比を指定
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="flex justify-end space-x-4 mt-4">
          <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded">キャンセル</button>
          <button onClick={handleSave} className="px-4 py-2 bg-blue-600 text-white rounded">保存</button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ImageCropModal;
