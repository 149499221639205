import React from 'react';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

interface DownloadButtonProps {
  previewRef: React.RefObject<HTMLDivElement>;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ previewRef }) => {
  const handleDownload = () => {
    console.log("Download button clicked");

    const node = previewRef.current;

    console.log(node); // 要素が正しく取得されているか確認

    if (node) {
      console.log("Element found, generating PNG...");

      htmlToImage.toPng(node)
        .then((dataUrl) => {
          download(dataUrl, 'poster.png');
          console.log("PNG generated and download triggered");
        })
        .catch((error) => {
          console.error('Error generating image:', error);
        });
    } else {
      console.log("Element not found");
    }
  };

  return (
    <button
      type="button"
      onClick={handleDownload}
      className="mt-4 bg-green-500 text-white p-2 rounded"
    >
      Download Poster as PNG
    </button>
  );
};

export default DownloadButton;
