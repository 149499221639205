import React from 'react';

interface AnimalTypeInputProps {
  animalType: string;
  setAnimalType: React.Dispatch<React.SetStateAction<string>>;
}

const AnimalTypeInput: React.FC<AnimalTypeInputProps> = ({ animalType, setAnimalType }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-2">探している動物:</label>
      <div>
        <label className="mr-4">
          <input
            type="radio"
            value="猫"
            checked={animalType === '猫'}
            onChange={(e) => setAnimalType(e.target.value)}
            className="mr-1"
          />
          猫
        </label>
        <label className="mr-4">
          <input
            type="radio"
            value="犬"
            checked={animalType === '犬'}
            onChange={(e) => setAnimalType(e.target.value)}
            className="mr-1"
          />
          犬
        </label>
        <label>
          <input
            type="radio"
            value="鳥"
            checked={animalType === '鳥'}
            onChange={(e) => setAnimalType(e.target.value)}
            className="mr-1"
          />
          鳥
        </label>
      </div>
    </div>
  );
};

export default AnimalTypeInput;
