import React from 'react';

interface MissingLocationInputProps {
  missingLocation: string;
  setMissingLocation: React.Dispatch<React.SetStateAction<string>>;
}

const MissingLocationInput: React.FC<MissingLocationInputProps> = ({ missingLocation, setMissingLocation }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">行方不明の場所:</label>
      <input
        type="text"
        value={missingLocation}
        onChange={(e) => setMissingLocation(e.target.value)}
        className="border p-2 w-full"
      />
    </div>
  );
};

export default MissingLocationInput;
