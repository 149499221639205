import React from 'react';

interface AgeInputProps {
  age: string;
  setAge: React.Dispatch<React.SetStateAction<string>>;
}

const AgeInput: React.FC<AgeInputProps> = ({ age, setAge }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 数字以外の文字を排除し、3桁までに制限
    if (value === '' || /^[0-9]{1,3}$/.test(value)) {
      setAge(value);
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700">年齢:</label>
      <input
        type="number"
        value={age}
        onChange={handleChange}
        max={999}
        className="border p-2 w-full"
        inputMode="numeric"
      />
    </div>
  );
};

export default AgeInput;
