import React from 'react';

interface GenderInputProps {
  gender: string;
  setGender: React.Dispatch<React.SetStateAction<string>>;
}

const GenderInput: React.FC<GenderInputProps> = ({ gender, setGender }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 mb-2">性別:</label>
      <div className="flex items-center">
        <label className="mr-4">
          <input
            type="radio"
            name="gender"
            value="オス"
            checked={gender === 'オス'}
            onChange={(e) => setGender(e.target.value)}
            className="mr-2"
          />
          オス
        </label>
        <label>
          <input
            type="radio"
            name="gender"
            value="メス"
            checked={gender === 'メス'}
            onChange={(e) => setGender(e.target.value)}
            className="mr-2"
          />
          メス
        </label>
      </div>
    </div>
  );
};

export default GenderInput;

