import React from 'react';

interface NameInputProps {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
}

const NameInput: React.FC<NameInputProps> = ({ name, setName }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">名前:</label>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="border p-2 w-full"
      />
    </div>
  );
};

export default NameInput;
