import React, { useRef, useState } from 'react';
import Preview from './components/Preview';
import PreviewDownload from './components/PreviewDownload';
import Form from './components/Form';

const App = () => {
  const [animalType, setAnimalType] = useState('猫');
  const [imageUrl1, setImageUrl1] = useState('');
  const [imageUrl2, setImageUrl2] = useState('');
  const [email, setEmail] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');  // 電話番号の状態を追加
  const [missingDate, setMissingDate] = useState('');
  const [missingLocation, setMissingLocation] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [collar, setCollar] = useState('');
  const [features, setFeatures] = useState('');
  const [notes, setNotes] = useState('');

  const previewRef = useRef<HTMLDivElement>(null);
  const hiddenPreviewRef = useRef<HTMLDivElement>(null);

  return (
    <div className="min-h-screen bg-gray-100 relative flex flex-col items-center py-10">
      {/* 表示用プレビュー */}
      <div className="fixed bottom-0 right-0 m-4 transform scale-75">
        <Preview
          ref={previewRef}
          animalType={animalType}
          imageUrl1={imageUrl1}
          imageUrl2={imageUrl2}
          email={email}
          twitterHandle={twitterHandle}
          phoneNumber={phoneNumber}  // 電話番号を渡す
          missingDate={missingDate}
          missingLocation={missingLocation}
          name={name}
          gender={gender}
          age={age}
          collar={collar}
          features={features}
          notes={notes}
        />
      </div>
      
      {/* 画像生成用の隠しプレビュー */}
      <div style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}>
        <PreviewDownload
          ref={hiddenPreviewRef}
          animalType={animalType}
          imageUrl1={imageUrl1}
          imageUrl2={imageUrl2}
          email={email}
          twitterHandle={twitterHandle}
          phoneNumber={phoneNumber}  // 電話番号を渡す
          missingDate={missingDate}
          missingLocation={missingLocation}
          name={name}
          gender={gender}
          age={age}
          collar={collar}
          features={features}
          notes={notes}
        />
      </div>

      <Form
        animalType={animalType}
        setAnimalType={setAnimalType}
        setImageUrl1={setImageUrl1}
        setImageUrl2={setImageUrl2}
        email={email}
        setEmail={setEmail}
        twitterHandle={twitterHandle}
        setTwitterHandle={setTwitterHandle}
        phoneNumber={phoneNumber}  // 電話番号
        setPhoneNumber={setPhoneNumber}  // 電話番号のsetter
        missingDate={missingDate}
        setMissingDate={setMissingDate}
        missingLocation={missingLocation}
        setMissingLocation={setMissingLocation}
        name={name}
        setName={setName}
        gender={gender}
        setGender={setGender}
        age={age}
        setAge={setAge}
        collar={collar}
        setCollar={setCollar}
        features={features}
        setFeatures={setFeatures}
        notes={notes}
        setNotes={setNotes}
        previewRef={hiddenPreviewRef}
      />
    </div>
  );
};

export default App;
