import React from 'react';

interface ContactInfoInputProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  twitterHandle: string;
  setTwitterHandle: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;  // 電話番号を追加
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;  // 電話番号のsetter
}

const ContactInfoInput: React.FC<ContactInfoInputProps> = ({ email, setEmail, twitterHandle, setTwitterHandle, phoneNumber, setPhoneNumber }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">連絡先情報:</label>
      <div className="mb-2">
        <label className="block text-sm text-gray-600">メールアドレス:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border p-2 w-full"
          placeholder="メールアドレスを入力してください"
        />
      </div>
      <div className="mb-2">
        <label className="block text-sm text-gray-600">電話番号:</label> {/* 電話番号フィールド */}
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="border p-2 w-full"
          placeholder="電話番号を入力してください"
        />
      </div>
      <div className="mb-2">
        <label className="block text-sm text-gray-600">X (旧Twitter) ユーザーネーム:</label>
        <input
          type="text"
          value={twitterHandle}
          onChange={(e) => setTwitterHandle(e.target.value)}
          className="border p-2 w-full"
          placeholder="Xのユーザーネームを入力してください"
        />
      </div>
    </div>
  );
};

export default ContactInfoInput;
