import React from 'react';
import ImageUpload from './ImageUpload';
import ContactInfoInput from './ContactInfoInput';
import AnimalTypeInput from './AnimalTypeInput';
import MissingDateInput from './MissingDateInput';
import MissingLocationInput from './MissingLocationInput';
import NameInput from './NameInput';
import GenderInput from './GenderInput';
import AgeInput from './AgeInput';
import CollarInput from './CollarInput';
import FeaturesInput from './FeaturesInput';
import NotesInput from './NotesInput';
import DownloadButton from './DownloadButton';

interface FormProps {
  animalType: string;
  setAnimalType: React.Dispatch<React.SetStateAction<string>>;
  setImageUrl1: React.Dispatch<React.SetStateAction<string>>;
  setImageUrl2: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  twitterHandle: string;
  setTwitterHandle: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;  // 電話番号を追加
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;  // 電話番号のsetter
  missingDate: string;
  setMissingDate: React.Dispatch<React.SetStateAction<string>>;
  missingLocation: string;
  setMissingLocation: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  gender: string;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  age: string;
  setAge: React.Dispatch<React.SetStateAction<string>>;
  collar: string;
  setCollar: React.Dispatch<React.SetStateAction<string>>;
  features: string;
  setFeatures: React.Dispatch<React.SetStateAction<string>>;
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  previewRef: React.RefObject<HTMLDivElement>;
}

const Form: React.FC<FormProps> = ({
  animalType,
  setAnimalType,
  setImageUrl1,
  setImageUrl2,
  email,
  setEmail,
  twitterHandle,
  setTwitterHandle,
  phoneNumber,
  setPhoneNumber,
  missingDate,
  setMissingDate,
  missingLocation,
  setMissingLocation,
  name,
  setName,
  gender,
  setGender,
  age,
  setAge,
  collar,
  setCollar,
  features,
  setFeatures,
  notes,
  setNotes,
  previewRef,
}) => {
  return (
    <form className="mt-8 w-full max-w-md px-4">
      <AnimalTypeInput animalType={animalType} setAnimalType={setAnimalType} />
      <MissingDateInput missingDate={missingDate} setMissingDate={setMissingDate} />
      <MissingLocationInput missingLocation={missingLocation} setMissingLocation={setMissingLocation} />
      <ImageUpload setImageUrl1={setImageUrl1} setImageUrl2={setImageUrl2} />
      <NameInput name={name} setName={setName} />
      <GenderInput gender={gender} setGender={setGender} />
      <AgeInput age={age} setAge={setAge} />
      <CollarInput collar={collar} setCollar={setCollar} />
      <FeaturesInput features={features} setFeatures={setFeatures} />
      <NotesInput notes={notes} setNotes={setNotes} />
      <ContactInfoInput
        email={email}
        setEmail={setEmail}
        twitterHandle={twitterHandle}
        setTwitterHandle={setTwitterHandle}
        phoneNumber={phoneNumber}  // 電話番号を渡す
        setPhoneNumber={setPhoneNumber}  // 電話番号のsetterを渡す
      />
      <DownloadButton previewRef={previewRef} />
    </form>
  );
};

export default Form;
