import React, { useState, useRef } from 'react';
import ImageCropModal from './ImageCropModal';

interface ImageUploadProps {
  setImageUrl1: React.Dispatch<React.SetStateAction<string>>;
  setImageUrl2: React.Dispatch<React.SetStateAction<string>>;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ setImageUrl1, setImageUrl2 }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [aspect, setAspect] = useState(1);  // 初期のアスペクト比を設定
  const fileInputRef1 = useRef<HTMLInputElement>(null); // 左側画像のリファレンス
  const fileInputRef2 = useRef<HTMLInputElement>(null); // 右側画像のリファレンス

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, target: 'imageUrl1' | 'imageUrl2') => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
        setShowModal(true);
        if (target === 'imageUrl1') {
          setAspect(372.050 / 760.912);  // 左側の画像用のアスペクト比
        } else {
          setAspect(380.050 / 376.450);  // 右側の画像用のアスペクト比
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCropSave = (croppedImage: string) => {
    if (aspect === 372.050 / 760.912) {
      setImageUrl1(croppedImage);
    } else {
      setImageUrl2(croppedImage);
    }
    setShowModal(false);
  };

  const handleCropCancel = () => {
    setShowModal(false);
    setImageSrc(null); // アップロード画像のクリア

    // ファイル入力をクリア
    if (aspect === 372.050 / 760.912 && fileInputRef1.current) {
      fileInputRef1.current.value = '';  // 左側のファイル入力をリセット
    } else if (aspect !== 372.050 / 760.912 && fileInputRef2.current) {
      fileInputRef2.current.value = '';  // 右側のファイル入力をリセット
    }
  };

  return (
    <div>
      <label className="block text-gray-700">写真をアップロード (左側):</label>
      <input
        type="file"
        ref={fileInputRef1}  // 左側の画像入力にrefを設定
        onChange={(e) => handleImageUpload(e, 'imageUrl1')}
        className="border p-2 w-full"
      />
      <label className="block text-gray-700 mt-4">写真をアップロード (右側):</label>
      <input
        type="file"
        ref={fileInputRef2}  // 右側の画像入力にrefを設定
        onChange={(e) => handleImageUpload(e, 'imageUrl2')}
        className="border p-2 w-full mt-4"
      />
      {showModal && imageSrc && (
        <ImageCropModal
          imageSrc={imageSrc}
          onSave={handleCropSave}
          onClose={handleCropCancel}  // モーダル閉じる際にキャンセル処理
          aspect={aspect}
        />
      )}
    </div>
  );
};

export default ImageUpload;
