import React from 'react';

interface FeaturesInputProps {
  features: string;
  setFeatures: React.Dispatch<React.SetStateAction<string>>;
}

const FeaturesInput: React.FC<FeaturesInputProps> = ({ features, setFeatures }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">マイクロチップ:</label>
      <div className="flex items-center space-x-4">
        <label className="flex items-center">
          <input
            type="radio"
            name="microchip"
            value="あり"
            checked={features === 'あり'}
            onChange={() => setFeatures('あり')}
            className="mr-2"
          />
          あり
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            name="microchip"
            value="なし"
            checked={features === 'なし'}
            onChange={() => setFeatures('なし')}
            className="mr-2"
          />
          なし
        </label>
      </div>
    </div>
  );
};

export default FeaturesInput;
