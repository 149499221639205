import React from 'react';

interface CollarInputProps {
  collar: string;
  setCollar: React.Dispatch<React.SetStateAction<string>>;
}

const CollarInput: React.FC<CollarInputProps> = ({ collar, setCollar }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">首輪:</label>
      <div className="flex items-center space-x-4">
        <label className="flex items-center">
          <input
            type="radio"
            name="collar"
            value="あり"
            checked={collar === 'あり'}
            onChange={() => setCollar('あり')}
            className="mr-2"
          />
          あり
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            name="collar"
            value="なし"
            checked={collar === 'なし'}
            onChange={() => setCollar('なし')}
            className="mr-2"
          />
          なし
        </label>
      </div>
    </div>
  );
};

export default CollarInput;
