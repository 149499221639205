import React, { forwardRef } from 'react';
import PreviewHeader from './PreviewHeader';
import PreviewContents from './PreviewContents';
import PreviewFooter from './PreviewFooter';

interface PreviewProps {
  animalType: string;
  imageUrl1: string;
  imageUrl2: string;
  email: string;
  twitterHandle: string;
  phoneNumber: string;  // 電話番号を追加
  missingDate: string;
  missingLocation: string;
  name: string;
  gender: string;
  age: string;
  collar: string;
  features: string;
  notes: string;
}

const Preview = forwardRef<HTMLDivElement, PreviewProps>(({
  animalType,
  imageUrl1,
  imageUrl2,
  email,
  twitterHandle,
  phoneNumber,  // 電話番号を受け取る
  missingDate,
  missingLocation,
  name,
  gender,
  age,
  collar,
  features,
  notes,
}, ref) => {
  return (
    <div
      ref={ref}
      className="border rounded-lg shadow-lg bg-white flex flex-col"
      style={{
        width: '210mm',  
        height: '297mm',  
        position: 'fixed',  
        bottom: '16px',  
        right: '16px',  
        zIndex: 1000,  
        transform: 'scale(0.3)',
        transformOrigin: 'bottom right',
      }}
    >
      <PreviewHeader animalType={animalType} />
      <PreviewContents
        animalType={animalType}
        imageUrl1={imageUrl1}
        imageUrl2={imageUrl2}
        missingDate={missingDate}
        missingLocation={missingLocation}
        name={name}
        gender={gender}
        age={age}
        collar={collar}
        features={features}
        notes={notes}
      />
      <PreviewFooter
        email={email}         // emailを渡す
        twitterHandle={twitterHandle} // twitterHandleを渡す
        phoneNumber={phoneNumber} // 電話番号を渡す
      />
    </div>
  );
});

export default Preview;
